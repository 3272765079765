"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/slicedToArray"));
var _flags = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/flags"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _entries = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/entries"));
var _lodash = _interopRequireDefault(require("lodash"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
var bufferTools = _interopRequireWildcard(require("@/libs/bufferTools"));
var _BufferSettings = _interopRequireDefault(require("./BufferSettings"));
var _StateBrowserBuffer = _interopRequireDefault(require("./StateBrowserBuffer"));
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var _default = {
  components: {
    BufferSettings: _BufferSettings.default,
    Buffer: _StateBrowserBuffer.default
  },
  props: ['network', 'sidebarState', 'activePrompt'],
  data: function data() {
    return {
      collapsed: false,
      channel_filter: '',
      channel_filter_display: false,
      channel_add_display: false,
      channel_add_input_has_focus: false,
      channel_add_input: '',
      show_channels: true,
      show_queries: true
    };
  },
  computed: {
    serverBuffer: function serverBuffer() {
      return this.network.serverBuffer();
    },
    isActiveNetwork: function isActiveNetwork() {
      return this.$state.getActiveNetwork() === this.network;
    },
    totalNetworkCount: function totalNetworkCount() {
      return this.$state.networks.length;
    },
    serverUnread: function serverUnread() {
      if (!this.collapsed) {
        return (0, _flags.default)(this.serverBuffer).unread;
      }
      var totalUnread = 0;
      this.network.buffers.forEach(function (buffer) {
        if (buffer.isSpecial()) {
          return;
        }
        totalUnread += (0, _flags.default)(buffer).unread;
      });
      return totalUnread;
    },
    serverHighlight: function serverHighlight() {
      if (!this.collapsed) {
        return (0, _flags.default)(this.serverBuffer).highlight;
      }
      var highlight = false;
      this.network.buffers.forEach(function (buffer) {
        if (buffer.isSpecial()) {
          return;
        }
        if ((0, _flags.default)(buffer).highlight) {
          highlight = true;
        }
      });
      return highlight;
    },
    filteredBuffers: function filteredBuffers() {
      var filter = this.channel_filter;
      var filtered = [];
      if (!filter) {
        filtered = this.network.buffers;
      } else {
        filtered = (0, _filter.default)(_lodash.default).call(_lodash.default, this.network.buffers, function (buffer) {
          var name = buffer.name.toLowerCase();
          return name.indexOf(filter) > -1;
        });
      }
      return bufferTools.orderBuffers(filtered);
    },
    filteredBuffersByType: function filteredBuffersByType() {
      var types = {
        other: [],
        channels: [],
        queries: []
      };
      this.filteredBuffers.forEach(function (bufferObj) {
        if (bufferObj.isChannel()) {
          types.channels.push(bufferObj);
        } else if (bufferObj.isQuery()) {
          types.queries.push(bufferObj);
        } else {
          // This is buffers like *raw, *bnc, *status etc
          types.other.push(bufferObj);
        }
      });
      (0, _entries.default)(types).forEach(function (_ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          type = _ref2[0],
          buffers = _ref2[1];
        // Always show channels type as it has join controls
        if (type !== 'channels' && !buffers.length) {
          delete types[type];
        }
      });
      return types;
    },
    channelActivity: function channelActivity() {
      return this.activityFromBuffers(this.filteredBuffersByType.channels);
    },
    queryActivity: function queryActivity() {
      return this.activityFromBuffers(this.filteredBuffersByType.queries);
    },
    showBufferGroups: function showBufferGroups() {
      return this.$state.setting('buffers.show_buffer_groups');
    },
    showPromptClose: function showPromptClose() {
      return this.activePrompt && this.activePrompt.type === 'queries' && this.activePrompt.value === this.network;
    }
  },
  methods: {
    activityFromBuffers: function activityFromBuffers(buffers) {
      var totalUnread = 0;
      var highlight = false;
      buffers.forEach(function (buffer) {
        if (buffer.isSpecial() || buffer.setting('hide_message_counts')) {
          return;
        }
        totalUnread += (0, _flags.default)(buffer).unread;
        if (!highlight && (0, _flags.default)(buffer).highlight) {
          highlight = true;
        }
      });
      return {
        highlights: highlight,
        unread: totalUnread
      };
    },
    onNewChannelInputFocus: function onNewChannelInputFocus() {
      // Auto insert the # if no value is already in. Easier for mobile users
      if (!this.channel_add_input) {
        this.channel_add_input = '#';
      }
      this.channel_add_input_has_focus = true;
    },
    onNewChannelInputBlur: function onNewChannelInputBlur() {
      // Remove the # since we may have auto inserted it as they tabbed past
      if (this.channel_add_input === '#') {
        this.channel_add_input = '';
      }

      // If nothing was entered into the input box, hide it just to clean up the UI
      if (!this.channel_add_input) {
        this.channel_add_display = false;
      }
      this.channel_add_input_has_focus = false;
    },
    submitNewChannelForm: function submitNewChannelForm() {
      var _this = this;
      var newChannelVal = this.channel_add_input;
      this.channel_add_input = '#';
      var network = this.network;
      var bufferObjs = Misc.extractBuffers(newChannelVal);

      // Only switch to the first channel we join if multiple are being joined
      var hasSwitchedActiveBuffer = false;
      bufferObjs.forEach(function (bufferObj) {
        var chanName = bufferObj.name;
        var ignoreNames = ['#0', '0', '&0'];
        if (ignoreNames.indexOf(chanName) > -1 || chanName.replace(/[#&]/g, '') === '') {
          return;
        }
        var newBuffer = _this.$state.addBuffer(network.id, chanName);
        if (newBuffer && !hasSwitchedActiveBuffer) {
          _this.$state.setActiveBuffer(network.id, newBuffer.name);
          hasSwitchedActiveBuffer = true;
        }
        if (bufferObj.key) {
          newBuffer.key = bufferObj.key;
        }
        if (network.isChannelName(chanName)) {
          network.ircClient.join(chanName, bufferObj.key);
        }
      });
    },
    onChannelFilterInputBlur: function onChannelFilterInputBlur() {
      var _this2 = this;
      // Hacky, but if we remove the channel filter UI at this blur event and the user
      // clicked a link in this filter UI, then the click event will not hit the target
      // link as it has been removed before the event reaches it.
      setTimeout(function () {
        _this2.closeFilterChannel();
      }, 200);
    },
    showMessageCounts: function showMessageCounts(buffer) {
      return !buffer.setting('hide_message_counts');
    },
    setActiveBuffer: function setActiveBuffer(buffer) {
      // Clear any active component to show the buffer again
      this.$state.$emit('active.component', null);
      this.$state.setActiveBuffer(buffer.networkid, buffer.name);
      if (this.$state.ui.is_narrow) {
        this.$state.$emit('statebrowser.hide');
      }
    },
    showNetworkSettings: function showNetworkSettings(network) {
      network.showServerBuffer('settings');
    },
    showNetworkChannels: function showNetworkChannels(network) {
      network.showServerBuffer('channels');
    },
    onSearchChannelClick: function onSearchChannelClick() {
      // If we have no other buffers than the server buffer, take them straight
      // to the channel list for searching
      if (this.network.buffers.length > 1) {
        this.toggleFilterChannel();
      } else {
        this.network.showServerBuffer('channels');
      }
    },
    toggleAddChannel: function toggleAddChannel() {
      this.channel_add_display = !this.channel_add_display;
      this.channel_filter_display = false;
    },
    toggleFilterChannel: function toggleFilterChannel() {
      this.channel_filter_display = !this.channel_filter_display;
      this.channel_add_display = false;
    },
    toggleSection: function toggleSection(type) {
      if (type === 'channels') {
        this.show_channels = !this.show_channels;
      } else if (type === 'queries') {
        this.show_queries = !this.show_queries;
      }
    },
    promptClose: function promptClose() {
      var prompt = this.activePrompt;
      if (this.showPromptClose) {
        // Prompt is currently visible so close it
        prompt.type = undefined;
        prompt.value = undefined;
      } else {
        prompt.type = 'queries';
        prompt.value = this.network;
      }
    },
    closeQueries: function closeQueries(buffers) {
      var _this3 = this;
      buffers.forEach(function (buffer) {
        _this3.$state.removeBuffer(buffer);
      });
    },
    closeFilterChannel: function closeFilterChannel() {
      this.channel_filter = '';
      this.channel_filter_display = false;
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["StateBrowserNetwork"]
window._kiwi_exports.components.StateBrowserNetwork = exports.default ? exports.default : exports;
